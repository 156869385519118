















































import Vue from 'vue'
import Component from "vue-class-component";
import {vxm} from "@/store";


@Component({
    components: {

    },
})

export default class Home extends Vue {
    private theUi = vxm.ui;
}
